let mainPath = "/dispatch";
let viewFolder = "Dispatch";

module.exports = [
    {
        path: mainPath,
        name: 'dispatches',
        component: () => import('../views/'+viewFolder+'/Index2.vue'),
    },
    {
        path: mainPath+'/view/:oid/',
        name: 'dispatch',
        component: () => import(/* webpackChunkName: "Warehouse" */ '../views/'+viewFolder+'/View.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (!localStorage.getItem('userId')) next({ name: 'Home' })
        //   else next()
        // }
    }
]