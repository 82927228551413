<template>
  <v-container fluid>
    <v-row v-if="loader2">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-progress-circular indeterminate color="success"/>
      </v-col>
    </v-row>
    <v-row v-if="!loader2">
      <v-col>
        <span>
          <span>Current Register {{getCurrentRegister}}</span>
          <v-card-title>Open Register</v-card-title>
          <v-card-text>
            <span v-if="registers.length===1">Using {{registers.find(x => data.registerId===x.id)?.name}}</span>
            <v-text-field outlined label="Opening Float" v-model="data.openingFloat" type="number"/>
            <v-select v-if="registers.length>1" label="Register" outlined :items="registers" item-text="name" item-value="id" v-model="data.registerId"/>
            <v-textarea outlined label="Notes" v-model="data.openingNotes"/>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" :loading="loader" :disabled="!canOpen" @click="openRegister">Open Register</v-btn>
          </v-card-actions>
        </span>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from "axios";

export default {
  name: 'Home',
  data () {
    return {
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      loader: false,
      loader2: false,

      data: {
        openingFloat: 0,
        registerId: null,
        openingNotes: ''
      },

      registers: []
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getBranch', 'getCurrentRegister']),
    canOpen(){
      if(this.data.openingFloat===null || this.data.openingFloat===undefined || this.data.openingFloat==='') return false;
      if(isNaN(this.data.openingFloat)) return false;
      if(this.data.openingFloat<0) return false;
      if(!this.data.registerId) return false;
      return true;
    }
  },
  async mounted(){
    try {
      this.loader2 = true;
      if(!this.getBranch) return
      let res = await axios.get(`${this.getEndpoint}/api/registers/byBranch/${this.getBranch}`)
      if(res.data.error) throw res.data.error
      this.registers = res.data.data
      this.updateCurrentRegister(res.data.registerUUID)

      if(this.registers.length>0) this.data.registerId = this.registers[0].id

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.loader2 = false;
    }
  },
  methods: {
    ...mapMutations(['updateCurrentRegister']),
    async openRegister(){
      try{
        this.loader = true;

        let res = await axios.post(`${this.getEndpoint}/api/registers/openRegister`, this.data)
        if(res.data.error) throw res.data.error
        this.updateCurrentRegister(res.data.registerUUID)

        this.snack(`Register Opened!`, "success");
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loader = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    }
  }
}
</script>
