<template>
  <v-container fluid>
    <v-progress-circular
        indeterminate
        color="green"
        v-if="initialLoader"
        style="margin-left: 10px;"
    ></v-progress-circular>
    <v-row style="" v-else>
      <v-col>
        <h1>Welcome {{lookupUsername(getId)}}</h1>
      </v-col>
    </v-row>
    <v-row v-if="getGlobalValue('VEC_SERIAL_SEARCH_ON_HOME_PAGE')==='true'">
      <v-col>
        <v-btn color="info" small @click="$router.push({path: '/products/serial/Search'})" >Search {{serialNaming.plural}} </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="getGlobalValue('registerModuleEnabled')==='true' && isAllowed('register', 'showOnHomePage')">
        <v-card outlined>
          <v-card-text>
            <OpenRegister v-if="getCurrentRegister==null"/>
            <CloseRegister v-else/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-row">
        <div v-if="getGlobalValue('VEC_HOME_CONTAINER_MAIN')" style="width: 100%;">
          <DynamicButtonContainer :containerId="`${getGlobalValue('VEC_HOME_CONTAINER_MAIN')}`" style="width: 100%;"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import OpenRegister from '../views/Register/Open.vue'
import CloseRegister from '../views/Register/Close.vue'
import DynamicButtonContainer from '../components/dynamicButtonContainer.vue'
export default {
  name: 'Home',
  components: {
    OpenRegister,
    CloseRegister,
    DynamicButtonContainer
  },
  data () {
    return {
      initialLoader: true,
      deliverySchedule: {
        tableHeaders:[
          {text: 'Delivery ID', align: 'start', value: 'id'},
          { text: 'Customer', value: 'Order.Customer.fullName' },
          { text: 'Delivery Date', value: 'dueDate' },
          { text: '# of Items', value: 'numItems' },
          { text: 'Order #', value: 'orderId' },
          { text: 'Status', value: 'status' },
        ],
        data: null,
      },
      orders: {
        tableHeaders:[
          {text: 'Order ID', align: 'start', value: 'id'},
          { text: 'Date Created', value: 'createdAt' },
          { text: 'CSR', value: 'CSR.fullName' },
          { text: 'Customer', value: 'Customer.fullName' },
          { text: 'Status', value: 'status' },
        ],
        data: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getPerms',
      'globalQueryBcAllowed',
      'getEndpoint',
      'isAllowed',
      'getUser',
      'getId',
      'scanBus',
      'lookupUsername',
      'lookupBranch',
      'envConstants',
      'getAllowedBranches',
      'getBranch',
      'isManaged',
      'menuItems',
      'getBranchData',
      'paymentTypes',
      'taxTypes',
      'paymentMethods',
      'activateScanSensePing',
      'getQuickList',
      'notificationSidebar',
      'getCurrentRegister',
      'getGlobalValue',
      'getUpdateCounter',
      'serialNaming',
      'darkTheme'
    ]),
  },
  async mounted(){
    try {
      console.log("HOME")
      console.log("HOME", this.getId, this.getUser, this.getId, this.isLoggedIn )
    } catch (error) {
      console.log(error)
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    //
  }
}
</script>
